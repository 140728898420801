$(document).ready(function () {
  $(".language-dropdown__current").on("click", function () {
    let current = $(this);
    let list = current.next();

    $(document).mouseup(function (e) {
      if (list.parent().has(e.target).length === 0) {
        list.fadeOut(150);
      }
    });

    list.fadeToggle(150);
  });

  $('.popup-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: {
      enabled: true
    }
  });

  $(".showMM").on("click", function () {
    let mm = $(".mm");

    // $(document).mouseup(function (e) {
    // 	if (list.parent().has(e.target).length === 0) {
    // 		list.fadeOut(150);
    // 	}
    // });

    mm.toggleClass('active');
  });

  $(".hideMM").on("click", function () {
    let mm = $(".mm");
    mm.removeClass('active');
  });

  $(".products-filter__title").on("click", function () {
    $(this).toggleClass('active');
    $(this).next().slideToggle(150);
  });

  $(".popup__close").on("click", function () {
    $(this).parent().parent().parent().fadeOut(300);
  });

  // $("body").on("click", function () {
  // 	let mm = $(".mm");
  // 	mm.removeClass('active');
  // });

  $(".filters__spoiler-header").on("click", function () {
    let list = $(this).next(),
      arrow = $(this).find(".filters__spoiler-arrow");


    if ($(window).width() < 768) {
      list.slideToggle(200);
    } else {
      list.fadeToggle(200);
    }
    arrow.toggleClass('active');

    $(document).mouseup(function (e) {
      if (list.parent().has(e.target).length === 0) {
        if ($(window).width() < 768) {
          list.slideUp(200);
        } else {
          list.fadeOut(200);
        }
        arrow.removeClass('active');
      }
    });


  });

  $('#new-products-slider').lightSlider({
    item: 1,
    adaptiveHeight: true,
    slideMargin: 0,
    pager: false,
    prevHtml: '<span><i class="far fa-angle-left"></i></span>',
    nextHtml: '<span><i class="far fa-angle-right"></i></span>',
  });

  $('#actions-slider').lightSlider({
    item: 1,
    adaptiveHeight: true,
    slideMargin: 15,
    pager: true,
    auto: true,
    loop: true,
    pause: 4000
  });

  $('#author-photos-slider').lightSlider({
    item: 4,
    adaptiveHeight: true,
    slideMargin: 30,
    pager: false,
    prevHtml: '<span><i class="far fa-angle-left"></i></span>',
    nextHtml: '<span><i class="far fa-angle-right"></i></span>',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          item: 4,
          slideMargin: 15
        }
      },
      {
        breakpoint: 992,
        settings: {
          item: 3,
          slideMargin: 15
        }
      },
      {
        breakpoint: 768,
        settings: {
          item: 2,
          slideMargin: 15
        }
      },
      {
        breakpoint: 480,
        settings: {
          item: 1,
          slideMargin: 15
        }
      }
    ]
  });

  $('#author-products-slider').lightSlider({
    item: 3,
    adaptiveHeight: true,
    slideMargin: 30,
    pager: false,
    prevHtml: '<span><i class="far fa-angle-left"></i></span>',
    nextHtml: '<span><i class="far fa-angle-right"></i></span>',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          item: 2,
          slideMargin: 15
        }
      },
      {
        breakpoint: 992,
        settings: {
          item: 1,
          slideMargin: 15
        }
      },
      {
        breakpoint: 768,
        settings: {
          item: 1,
          slideMargin: 15
        }
      },
      {
        breakpoint: 480,
        settings: {
          item: 1,
          slideMargin: 15
        }
      }
    ]
  });

  $('#product-reviews-slider').lightSlider({
    item: 2,
    adaptiveHeight: true,
    slideMargin: 30,
    pager: false,
    prevHtml: '<span><i class="far fa-angle-left"></i></span>',
    nextHtml: '<span><i class="far fa-angle-right"></i></span>',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          item: 2,
          slideMargin: 15
        }
      },
      {
        breakpoint: 992,
        settings: {
          item: 1,
          slideMargin: 15
        }
      }
    ]
  });

  let psMainSlider = $('#ps-main-slider').lightSlider({
    item: 1,
    adaptiveHeight: false,
    slideMargin: 0,
    pager: false
  });

  let psMPhotosSlider = $('#ps-mphotos-slider').lightSlider({
    item: 3,
    adaptiveHeight: false,
    slideMargin: 5,
    pager: false,
    prevHtml: '<span><i class="fa fa-angle-left"></i></span>',
    nextHtml: '<span><i class="fa fa-angle-right"></i></span>',
    responsive: [
      {
        breakpoint: 540,
        settings: {
          item: 2,
          slideMargin: 5
        }
      }
    ]
  });

  let psMVideosSlider = $('#ps-mvideos-slider').lightSlider({
    item: 2,
    adaptiveHeight: false,
    slideMargin: 5,
    pager: false,
    prevHtml: '<span><i class="fa fa-angle-left"></i></span>',
    nextHtml: '<span><i class="fa fa-angle-right"></i></span>'
  });

  let psDPhotosSlider = $('#ps-dphotos-slider').lightSlider({
    item: 3,
    adaptiveHeight: false,
    slideMargin: 5,
    pager: false,
    vertical: true,
    verticalHeight: 185.1,
    prevHtml: '<span><i class="fa fa-angle-up"></i></span>',
    nextHtml: '<span><i class="fa fa-angle-down"></i></span>',
    responsive: [
      // {
      // 	breakpoint: 1200,
      // 	settings: {
      // 		verticalHeight: 233.43,
      // 		slideMargin: 5,
      // 		item: 3
      // 	}
      // },
      {
        breakpoint: 1200,
        settings: {
          verticalHeight: 185.1,
          slideMargin: 5,
          item: 3
        }
      }
    ]
  });

  let psDVideosSlider = $('#ps-dvideos-slider').lightSlider({
    item: 2,
    adaptiveHeight: false,
    slideMargin: 5,
    pager: false,
    vertical: true,
    verticalHeight: 123.4,
    prevHtml: '<span><i class="fa fa-angle-up"></i></span>',
    nextHtml: '<span><i class="fa fa-angle-down"></i></span>',
    responsive: [
      // {
      // 	breakpoint: 1200,
      // 	settings: {
      // 		verticalHeight: 155.62,
      // 		slideMargin: 5,
      // 		item: 2
      // 	}
      // },
      {
        breakpoint: 1200,
        settings: {
          verticalHeight: 123.4,
          slideMargin: 5,
          item: 2
        }
      }
    ]
  });

  $(".ps-thumbs-link").on("click", function () {
    var slideNumber = $(this).data('slide');

    psMainSlider.goToSlide(slideNumber);
  });

  $('body').on('click', '.buy-one-click', function (e) {
    e.preventDefault();
    var productId = $(this).attr('data-id');
    $.ajax({
      type: 'post',
      url: '/basket/buy-one-click',
      data: {
        '_token': $('meta[name="csrf-token"]').attr('content'),
        'product_id': productId,
        'phone': $('#phone-one-click').val()
      },
      success: function (response) {
        if (response.errors) {
          var key, label, errors = response.errors, string = '';

          for (key in errors) {
            string += errors[key];
          }
          alert(string);
        } else {
          $("#success-shop").show().find('.popup__title').text(response.success);
          $('#phone-one-click').val('')
        }
      },
      error: function (response) {
        console.log('Відбулась помилка на сервері, попробуйте пізніше, або повідомте про це адміністрацію');
      }
    });
  });

  $('.share-present-button').on('click', function (e) {
    e.preventDefault();
    var productId = $(this).attr('data-id');
    $('#hidden-present-product').val(productId);
    $('#want-present').show();
  });

  $('.send-present-button').on('click', function (e) {
    e.preventDefault();

    var app = $(this).closest('form');
    app.find('span.auth-form__error').remove();
    app.addClass('loader');

    $.ajax({
      url: app.attr('action'),
      type: app.attr('method'),
      dataType: "html",
      data: app.serialize(),
      success: function (response) {
        response = JSON.parse(response);
        if (response.errors) {
          var key, errors = response.errors;

          for (key in errors) {
            app.find($('textarea[name=' + key + ']'))
              .closest("label.auth-form__item")
              .after('<span class="auth-form__error">' + errors[key] + '</span>');
          }
          console.log('test');
        } else {
          app[0].reset();
          $('#want-present').hide();
          $("#success-shop").show().find('.popup__title').text(response.success);
          $('#phone-one-click').val('')
        }
        setTimeout(function () {
          app.removeClass('loader');
        }, 500);
      },
      error: function (response) {
        app.removeClass('loader');
        console.log('error in server');
      }
    });
  });

  $('body').on('click', '.add-to-basket', function (e) {
    e.preventDefault();
    var productId = $(this).attr('data-id');
    var newWithParams = $(this).attr('data-with-params');
    if (newWithParams == 'true') {
      var filter1html = $('body').find('input[name=filter1]');
      var filter3html = $('body').find('input[name=filter3]');

      var filter1 = $('input[name=filter1]:checked', '.product__filters').val();
      var filter3 = $('input[name=filter3]:checked', '.product__filters').val();

      if (filter1html.length) {
        if (filter1 === undefined) {
          var text = $('#size').html();
          alert('Виберете ' + text);
          return false;
        }
      }

      if (filter3html.length) {
        if (filter3 === undefined) {
          var text = $('#color').html();
          alert('Виберете ' + text);
          return false;
        }
      }
      card(productId, filter1, filter3);
      //with parms
    } else {
      card(productId);
    }
    //find first button
    //find two radio

    $('#add-product-to-basket').show();
    setTimeout(function () {
      $('#add-product-to-basket').hide()
    }, 3000);
  });

  card(0);

  function card(productId, filter1='', filter3 ='') {
    $.ajax({
      type: 'post',
      url: '/basket/add-product',
      data: {
        '_token': $('meta[name="csrf-token"]').attr('content'),
        'product_id': productId,
        'filter1': filter1,
        'filter3': filter3
      },
      success: function (result) {
        if (result.count >= 1) {
          $('.basket-count-products').show().html(result.count);
        }
      }
    });
  }

  $("body").on('change', "input[type='checkbox']", function () {
    if ('subcategory[]' == $(this).attr('name')) {
      var slug = $(this).attr('data-slug');
      var selected = [];
      $('.subcategory-dcp-filter input:checked').each(function () {
        selected.push($(this).val());
      });

      $.ajax({
        url: '/more-dcp-filters',
        type: "get",
        data: {
          'categoriesIds': selected
        },
        success: function (response) {
          $('#dcp-filter-block').html(response.html);
        }
      });
    }
    filters(false);
  });

  $("input[type='text']").on('change', function () {
    filters(false);
  });

  function filters(page) {
    var thisForm = $(".filter-form");
    var activeSort = $('.stock_type.active');
    var stockSort = activeSort.attr('data-value');
    var field;
    var value;

    var param = '';
    if (stockSort != undefined) {
      param = param + 'field=stock_status_id&stock_type=' + stockSort;
    }

    var order = activeSort.attr('data-order');
    if (order != undefined) {
      param = param + 'field=price&order=' + order;
    }

    if (page) {
      param = param + '&page=' + page;
    }

    param = thisForm.serialize() + '&' + param;

    $.ajax({
      url: thisForm.attr('action'),
      type: "get",
      dataType: "html",
      data: param,
      success: function (response) {
        var response = JSON.parse(response);
        $('.filter-results').html(response.html);
        $('#render-pagination').html(response.pagination);
      },
      error: function (response) {
        console.log('error in server');
      }
    });
  }

  $('body').on('click', '.page-link', function (e) {
    e.preventDefault();
    var app = $(this).attr('href');
    var array = app.match(/\d+/);
    var page = array[0];
    filters(page);
    $("html,body").animate({scrollTop: 0}, 500);
  });

  $("#price").on('click', function (e) {
    e.preventDefault();
    var order = $(this).attr('data-order');
    if (order == 'asc') {
      order = 'desc';
    } else {
      order = 'asc';
    }
    $(this).attr('data-order', order);
  });

  $('.stock_type').on('click', function (e) {
    var dataValue = $(this).attr('data-value');
    $('.sorting__list a').each(function (i, el) {
      if ($(this).hasClass("stock_type")) {
        if (dataValue == $(this).attr('data-value')) {
          $(this).addClass('active');
        } else {
          $(this).removeClass('active');
        }
      }
    });

    filters(false);
  });

  $('#plus-count-product').on('click', function () {
    var tmp = $('#total-shop-products').text();
    tmp++;
    $('#total-shop-products').text(tmp);
  });

  $('#minus-count-product').on('click', function () {
    var tmp = $('#total-shop-products').text();
    if (tmp != 1) {
      tmp--;
      $('#total-shop-products').text(tmp);
    }
  });

  $(".kurer").on('change', function () {
    if ($(this).val() == 1) {
      $("#delivery-price").show();
    } else {
      $("#delivery-price").hide();
    }
  });
});
